import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

export const HelpText: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "text-sm [&_a:visited]:text-inherit [&_a]:underline",
        {
          "text-zi-secondaryText": !interfacesTheme,
          "text-muted-foreground": interfacesTheme,
        },
        props.className
      )}
    />
  );
};
