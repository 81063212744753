import { Kanban } from "../Kanban";
import { Media } from "../Media";
import { Table } from "../Table";
import { MarkdownText } from "../MarkdownText";
import { Embed } from "../Embed";
import { Form } from "../Form/Form";
import { Links } from "../Links/Links";
import { blockType } from "./constants";
import { LayoutAllowedItem, LayoutBlockSchema } from "./schema";
import { Layout, LayoutBlock } from "./types";
import { Chatbot } from "../Chatbot";
import { ButtonBlockDefinition } from "../Button";
import { Checklist } from "block-system/blocks/Checklist";
import { StripePayment } from "../StripePayment";
import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import cuid from "cuid";

function copyChildBlock(childBlock: LayoutAllowedItem) {
  const blockType = childBlock.type;
  switch (blockType) {
    case "chatbot-block":
      return Chatbot.copy(childBlock);
    case "table-listing-block":
      return Table.copy(childBlock);
    case "kanban-block":
      return Kanban.copy(childBlock);
    case "media-block":
      return Media.copy(childBlock);
    case "markdown-text-block":
      return MarkdownText.copy(childBlock);
    case "embed-block":
      return Embed.copy(childBlock);
    case "form-block":
      return Form.copy(childBlock);
    case "links-block":
      return Links.copy(childBlock);
    case "button-block":
      return ButtonBlockDefinition.copy(childBlock);
    case "checklist-block":
      return Checklist.copy(childBlock);
    case "stripe-payment-block":
      return StripePayment.copy(childBlock);
    default:
      const _exhaustiveCheck: never = blockType;
      return _exhaustiveCheck;
  }
}

export function copy(block: LayoutBlock): LayoutBlock {
  // We need to make sure that when copying, the ordering of children are preserved
  // And for that, we need to set new ids and map those to current ordering.
  const currentOrdering = block.config.ordering;
  const newOrdering: Record<string, number> = {};

  const copiedChildren = block.children.map((child, index) => {
    const currentId = child.config.id;
    const newId = cuid();

    newOrdering[newId] = currentId ? currentOrdering[currentId] : index;

    const copiedChild = copyChildBlock(child);
    copiedChild.config.id = newId;

    return copiedChild;
  });

  return {
    ...block,
    config: {
      ...block.config,
      ordering: newOrdering,
      id: undefined,
    },
    children: copiedChildren as LayoutAllowedItem[],
  };
}

export const create: Layout["create"] = (
  props?: BlockCreateProps<LayoutBlock> & { children: LayoutBlock["children"] }
) => {
  const children = props?.children ?? [];
  const providedConfig = props?.initialConfig ?? {};

  const initialData: LayoutBlock = {
    type: blockType,
    config: {
      title: "Layout",
      columnCount: 3,
      itemVerticalAlignments: ["center", "center", "center"],
      ordering: {},
      style: {
        alignment: "center",
        width: "wide",
      },
      ...providedConfig,
    },
    children,
  };

  return LayoutBlockSchema.parse(initialData);
};

export const map: Layout["map"] = (block) => {
  return {
    type: blockType,
    config: {
      ...LayoutBlockSchema.shape.config.parse(block.config),
      id: block.id,
    },
    children: block.children.map((child) => {
      switch (child.type) {
        case "Chatbot":
          return Chatbot.map(child);
        case "TableListing":
          return Table.map(child);
        case "Kanban":
          return Kanban.map(child);
        case "Media":
          return Media.map(child);
        case "MarkdownText":
          return MarkdownText.map(child);
        case "Embed":
          return Embed.map(child);
        case "Form":
          return Form.map(child);
        case "Links":
          return Links.map(child);
        case "Button":
          return ButtonBlockDefinition.map(child);
        case "Checklist":
          return Checklist.map(child);
        case "StripePayment":
          return StripePayment.map(child);
        default:
          throw new Error(`Unexpected parent block type: ${block.type}`);
      }
    }),
  };
};
