import { cn } from "utils/cn";
import { ALIGNMENT_TO_CLASSNAMES, MAX_WIDTH_TO_CLASSNAMES } from "./constants";
import { BlockWrapperProps } from "./types";

export function PublishedBlockWrapper(props: BlockWrapperProps) {
  return (
    <div className={cn("relative w-full", props.className)}>
      <div
        className={cn(
          "relative",
          ALIGNMENT_TO_CLASSNAMES[props.alignment ?? "center"],
          MAX_WIDTH_TO_CLASSNAMES[props.maxWidth ?? "100%"]
        )}
      >
        <div className="relative scroll-mx-0 scroll-my-10">
          {props.children}
        </div>
      </div>
    </div>
  );
}
