import ReactTextareaAutosize from "react-textarea-autosize";
import { ChangeEvent, forwardRef, useMemo } from "react";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof ReactTextareaAutosize>,
  "value" | "onChange"
> & {
  value?: string;
  onChange?: (value: string) => void;
  isErrored?: boolean;
  disableResize?: boolean;
};

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ onChange, isErrored, disableResize, ...props }, ref) => {
    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (e: ChangeEvent<HTMLTextAreaElement>) => {
            onChange(e.target.value);
          }
        : undefined;
    }, [onChange]);

    const interfacesTheme = useInterfacesTheme();

    return (
      <ReactTextareaAutosize
        {...props}
        ref={ref}
        onChange={handleChange}
        className={cn(
          "block min-h-[50px] w-full border-2 border-solid p-[15px] text-[17px]",
          {
            "resize-none": disableResize,
            "resize-y": !disableResize,
            "rounded-lg border-zi-lightGray bg-zi-superLightGray text-zi-text focus-within:border-zi-primary":
              !interfacesTheme,
            "rounded-large border-input bg-card text-card-foreground focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background":
              interfacesTheme,
            "border-zi-formError": isErrored && !interfacesTheme,
            "border-destructive": isErrored && interfacesTheme,
          }
        )}
        value={props.value ?? ""}
      />
    );
  }
);

TextArea.displayName = "TextArea";
