import type { SupportedCurrencies } from "block-system/blocks/Form/Field/types";
import { renderCurrencyIcon } from "block-system/utilities/currency-helpers";
import { forwardRef } from "react";
import { TextInput, Props as TextInputProps } from "./TextInput";

type Props = TextInputProps & {
  currencyFormat: SupportedCurrencies;
  decimals: number;
};

export const Currency = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, currencyFormat, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        renderIconBefore={() => renderCurrencyIcon(currencyFormat)}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);

Currency.displayName = "Currency";
