import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { ChangeEvent, forwardRef, ReactNode, useMemo } from "react";
import { cn } from "utils/cn";

const IconWrapper: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn("flex h-full grow-0 items-center justify-center", {
        "text-zi-placeholder": !interfacesTheme,
        "text-muted-foreground": interfacesTheme,
      })}
    />
  );
};

type InputProps = Omit<
  React.ComponentPropsWithoutRef<"input">,
  "onChange" | "size" | "type" | "value"
>;

export type Props = InputProps & {
  value?: string;
  onChange?: (value: string) => void;
  type?: "email" | "password" | "search" | "tel" | "text";
  size?: "small" | "medium";
  isErrored?: boolean;
  renderIconBefore?: () => ReactNode;
  renderIconAfter?: () => ReactNode;

  /**
   * These props are superfluous here.
   * We have them defined in `packages/service/src/block-system/components/forms/Field.tsx` but we never used them.
   * They caused React errors related to unknown props.
   *
   * I'm deferring the refactor for now.
   */
  isDisabled?: boolean;
  isRequired?: boolean;
};

export const TextInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, isErrored, size, isDisabled, isRequired, ...props }, ref) => {
    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.value);
          }
        : undefined;
    }, [onChange]);

    const interfacesTheme = useInterfacesTheme();

    return (
      <div
        data-errored={isErrored}
        data-size={size}
        className={cn(
          "flex h-[50px] flex-row flex-nowrap items-center gap-x-2.5 overflow-hidden border-2 px-4 py-0 text-[17px] transition-colors duration-150 data-[size=small]:h-10 data-[size=small]:text-[15px]",
          {
            "rounded-lg border-zi-lightGray bg-zi-superLightGray text-zi-text focus-within:border-zi-primary data-[errored=true]:border-zi-formError":
              !interfacesTheme,
            "rounded-large border-input bg-card text-card-foreground focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-offset-background data-[errored=true]:border-destructive":
              interfacesTheme,
          }
        )}
      >
        {props.renderIconBefore ? (
          <IconWrapper>{props.renderIconBefore()}</IconWrapper>
        ) : null}
        <input
          ref={ref}
          onChange={handleChange}
          {...props}
          required={isRequired ?? false}
          disabled={props.disabled ?? isDisabled ?? false}
          value={props.value ?? ""}
          className={cn(
            "block h-full w-0 grow border-none bg-transparent px-0 py-4 text-inherit outline-none",
            {
              "placeholder:text-zi-placeholder": !interfacesTheme,
              "placeholder:text-muted-foreground": interfacesTheme,
            }
          )}
        />
        {props.renderIconAfter ? (
          <IconWrapper>{props.renderIconAfter()}</IconWrapper>
        ) : null}
      </div>
    );
  }
);

TextInput.displayName = "TextInput";
