import React, { useId } from "react";
import { Icon } from "@zapier/design-system";

import { FormLabel } from "./FormLabel";
import { HelpText } from "./HelpText";
import { FormError } from "./FormError";

type RenderInputOptions = {
  "aria-describedby"?: string;
  id: string;
  isDisabled: boolean;
  isErrored: boolean;
  isRequired: boolean;
};

type RenderLabelProps = {
  htmlFor: string;
  isDisabled?: boolean;
  isErrored?: boolean;
  isRequired?: boolean;
  label: string;
  requiredText?: React.ReactNode;
};

export type Props = {
  /**
   * Optional error message for the field. If it exists, the field will be
   * considered to have an error.
   */
  error?: string;
  /**
   * The number of help text lines to show (defaults to 1). Any extra text will be
   * hidden behind a "more" button.
   */
  helpTextLineClamp?: number;
  /**
   * `id` for the input field. If this isn't supplied, then one will be generated
   * to properly link the `label` to the input.
   */
  inputId?: string;
  /**
   * Indicates whether the field is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the field is required.
   */
  isRequired?: boolean;
  /**
   * Text for the `label` element for this field.
   */
  label: string;
  /**
   * Function that returns the help text to be rendered beneath the input.
   */
  renderHelpText?: () => React.ReactNode;
  /**
   * Function that returns the rendered input field.
   */
  renderInput: (opts: RenderInputOptions) => React.ReactNode;
  /**
   * Function that returns the label to be rendered.
   */
  renderLabel?: (labelProps: RenderLabelProps) => React.ReactNode;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
  /**
   * Optional boolean to not display the field label.
   */
  hideLabel?: boolean;
};

const defaultProps = {
  helpTextLineClamp: 1,
};

/**
 * Wraps an input field and renders a label and optional help text.
 */
export function Field(_props: Props) {
  const props = {
    ...defaultProps,
    ..._props,
  };

  /**
   * Sadly, even the usage of `useId` does not prevent the "hydration mismatch" errors.
   * According to the documentation, the `useId` requires the whole _tree_ to be the same on the server and on the client.
   * This appears not be the case when we render this component, as such the `useId` hook returns different ids.
   */
  const customId = useId();
  const id = props.inputId ?? customId;

  const describedById = `${id}-description`;
  const helpText = props.renderHelpText && props.renderHelpText();

  return (
    <div className="flex flex-col gap-y-2.5">
      {!props.hideLabel && (
        <div>
          {props.renderLabel ? (
            props.renderLabel({
              htmlFor: id,
              isDisabled: props.isDisabled,
              isErrored: !!props.error,
              isRequired: props.isRequired,
              label: props.label,
              requiredText: props.requiredText,
            })
          ) : (
            <FormLabel
              alignItems="start"
              htmlFor={id}
              isDisabled={props.isDisabled}
              isErrored={!!props.error}
              isRequired={props.isRequired}
              requiredText={props.requiredText}
              size="small"
            >
              {props.error ? (
                <Icon isBlock={true} name="alertTriangle" size={18} />
              ) : null}
              <span>{props.label}</span>
            </FormLabel>
          )}
        </div>
      )}
      {props.renderInput({
        "aria-describedby": helpText || props.error ? describedById : undefined,
        id,
        isDisabled: !!props.isDisabled,
        isErrored: !!props.error,
        isRequired: !!props.isRequired,
      })}
      {props.error ? <FormError>{props.error}</FormError> : null}
      {helpText ? <HelpText>{helpText}</HelpText> : null}
    </div>
  );
}
