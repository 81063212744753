import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";
import { ConsumerMe, trpc } from "utils/trpc";

export function LoggedInConsumerLabel(props: { consumer: ConsumerMe }) {
  const utils = trpc.useContext();

  const interfacesTheme = useInterfacesTheme();

  const logout = async () => {
    try {
      await utils.projectAuth.logout.fetch();
    } catch (error) {
      console.error("Failed to logout:", error);
    } finally {
      /**
       * Historically, we used the `router.replace("/")` here.
       * That did not work, since Next.js will NOT re-mount the component
       * if we navigate to the same page we are currently on.
       *
       * https://nextjs.org/docs/pages/api-reference/functions/use-router#resetting-state-after-navigation
       */
      window.location.href = "/";
    }
  };

  return (
    <div
      className={cn(
        "mt-[50px] flex justify-center pb-[50px] pt-[5px] font-sans text-sm font-normal [&_a]:transition",
        interfacesTheme
          ? "text-foreground [&_a:hover]:text-primary [&_a]:text-muted-foreground"
          : "text-zi-secondaryText [&_a:hover]:text-zi-primary [&_a]:text-zi-secondaryText"
      )}
    >
      Logged in as {`${props.consumer.email}`} &nbsp;{" "}
      <a href="#" onClick={() => logout()} data-testid="bottom-page-logout">
        Log out
      </a>
    </div>
  );
}
