import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { forwardRef, useMemo } from "react";
import Select, { components, Props as SelectProps } from "react-select";
import { cn } from "utils/cn";

export type Option = { label: string; value: string };
export type Options = Option[];

type AllSelectProps = Required<SelectProps<string | Option>>;
type SelectComponents = AllSelectProps["components"];

const defaultComponents: SelectComponents = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Input: ({ autoComplete: _autoComplete, ...props }) => {
    return <components.Input {...props} />;
  },
};

export type Props = {
  value?: string | string[];
  name?: string;
  placeholder?: string;
  options: Option[];
  multiSelect?: boolean;
  isErrored?: boolean;
  onChange?: (newValue: string | string[] | null) => void;
  size?: "small" | "medium";
  ariaLabel?: string;
} & Omit<SelectProps, "onChange">;

export const DropdownSelect = forwardRef<any, Props>(
  (
    {
      value,
      name,
      placeholder,
      options,
      multiSelect,
      isErrored,
      onChange,
      size = "medium",
      ariaLabel,
      isDisabled,
      className,
    },
    ref
  ) => {
    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (value: string | string[]) => {
            onChange(value);
          }
        : undefined;
    }, [onChange]);

    const interfacesTheme = useInterfacesTheme();

    return (
      <Select
        className={cn("custom-select", className, {
          "interfaces-theme": interfacesTheme,
          "custom-select--errored": isErrored,
          "custom-select--small": size === "small",
          "custom-select--multi": multiSelect,
        })}
        components={defaultComponents}
        inputId={name}
        ref={ref}
        placeholder={placeholder}
        value={
          multiSelect
            ? options.filter((option) => value?.includes(option.value))
            : (options.find((option) => option.value === value) ?? "")
        }
        isMulti={multiSelect ?? false}
        name={name}
        options={options}
        isDisabled={isDisabled}
        onChange={(selectedOption) => {
          if (!handleChange) return;
          if (multiSelect) {
            handleChange(
              (selectedOption as Option[])?.map((option) => option.value)
            );
          } else handleChange((selectedOption as Option)?.value);
        }}
        aria-label={ariaLabel}
        classNamePrefix="Select"
        formatOptionLabel={(option) => {
          if (typeof option === "string") {
            return <span data-testid={`select-option-label`}>{option}</span>;
          }

          return (
            <span data-testid={`select-option-label`}>{option.label}</span>
          );
        }}
        closeMenuOnSelect={!multiSelect}
      />
    );
  }
);

DropdownSelect.displayName = "DropdownSelect";
