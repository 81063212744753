import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

type Props = {
  /**
   * The content to render within `FormLabelRequiredText`.
   *
   * @default Required
   */
  children?: React.ReactNode;
};

const defaultProps = {
  children: "required",
};

/**
 * `FormLabelRequiredText` indicates whether a field is required.
 * Should always be a descendant of a `Label`.
 */
export const FormLabelRequiredText = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };

  const interfacesTheme = useInterfacesTheme();

  return (
    // `aria-hidden` to prevent it from being read redundantly.
    // The associated `input` field should indicate whether it's
    // required to screenreaders.
    <span
      className={cn(
        "ml-0.5 font-normal before:content-['('] after:content-[')']",
        {
          "text-zi-secondaryText": !interfacesTheme,
          "text-muted-foreground": interfacesTheme,
        }
      )}
      aria-hidden="true"
    >
      {props.children}
    </span>
  );
};
