import { Fragment, Suspense } from "react";
import { ContentBlocks } from "block-system/types";
import { blockHelpers } from "block-system/utilities";
import { BlockError } from "./BlockError";
import { Block } from "block-system";
import { BlockSpinner } from "block-system/components/BlockSpinner";
import * as Sentry from "@sentry/nextjs";

export function Blocks(props: {
  blocks: ContentBlocks;
  bare?: boolean;
  parentId?: string;
}) {
  const renderBlocks = () => {
    return props.blocks.map((block, i) => {
      const blockId = blockHelpers.joinIds(props.parentId, i.toString());

      return (
        <Sentry.ErrorBoundary
          key={i}
          fallback={() => <BlockError blockId={blockId} />}
        >
          <Suspense fallback={<BlockSpinner />}>
            <Block block={block} blocks={props.blocks} blockId={blockId} />
          </Suspense>
        </Sentry.ErrorBoundary>
      );
    });
  };

  return <Fragment>{renderBlocks()}</Fragment>;
}
