import { useTheme } from "lib/theme";
import { ColorName } from "lib/theme/themes/app-theme";
import { ComponentProps, ReactNode } from "react";
import typography from "./typography";

export type TextType = keyof typeof typography;

export function Text<TAs extends keyof JSX.IntrinsicElements>(
  props: {
    type: TextType;
    as?: TAs;
    color?: ColorName;
    children: ReactNode;
    "data-testid"?: string;
  } & Omit<ComponentProps<TAs>, "color">
) {
  const { type, color, ...rest } = props;
  const Component = typography[type];

  const theme = useTheme();

  const style = color ? { color: theme.app.colors[color] } : undefined;

  return (
    <Component style={style} {...rest}>
      {props.children}
    </Component>
  );
}
