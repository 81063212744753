import { forwardRef } from "react";
import { TextInput } from "./TextInput";

type Props = {
  placeholder?: string;
};

export const UrlInput = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, ...props }, ref) => {
    return (
      <TextInput
        ref={ref}
        placeholder={placeholder}
        inputMode="url"
        {...props}
      />
    );
  }
);

UrlInput.displayName = "UrlInput";
