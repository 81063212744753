import { useIsFetching, useMutation } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { Link, Spinner } from "@zapier/design-system";
import { isConnectedAccountNotFoundError } from "block-system/blocks/StripePayment/utils";
import { Text } from "block-system/components/Text";
import { IconStripe } from "block-system/components/icons/IconStripe";
import { STRIPE_TERMS, StripeTermsModal } from "components/Stripe/StripeTerms";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { ProjectStripeConnectedAccount } from "server/schemas/projects";
import { getStripeDashboardUrlForAccount } from "server/utils/stripe";
import { trpc } from "utils/trpc";

/**
 * Note that the `ProjectStripeConnectedAccount["status"]` is not using Pick<T> or Omit<>.
 * This is intentional! We want to to force TS to error out here when a new status is added.
 */
export const TitleForStatus: Record<
  ProjectStripeConnectedAccount["status"],
  string
> = {
  Disabled: "No Stripe account connected.",
  NeedsMoreInfo: "Missing information in Stripe.",
  PendingVerification: "Stripe account verification pending.",
  /**
   * We should never use this key in the UI.
   * It's only here because it is required by the type.
   */
  Enabled: "",
};

export function AccountDisabledBlockWarning({ blockId }: { blockId: string }) {
  return (
    <AccountBlockWarning blockId={blockId} title={TitleForStatus["Disabled"]} />
  );
}

export function AccountPendingVerificationBlockWarning({
  blockId,
}: {
  blockId: string;
}) {
  return (
    <AccountBlockWarning
      blockId={blockId}
      title={TitleForStatus["PendingVerification"]}
    />
  );
}

function AccountBlockWarning({
  blockId,
  title,
}: {
  blockId: string;
  title: string;
}) {
  return (
    <div
      data-testid={`payment-block-warning-${blockId}`}
      className="flex w-full items-center gap-[20px] rounded-[5px] border border-gray-300 bg-zi-white px-[20px] py-[14px]"
    >
      <div
        className={"rounded-[5px] border border-gray-200 bg-zi-white p-[5px]"}
      >
        <IconStripe size={30} />
      </div>
      <Text type="paragraph3Bold">{title}</Text>
    </div>
  );
}

export function EditorContentPanelAccountWarning({
  blockId,
  stripeConnectedAccount,
}: {
  blockId: string;
  stripeConnectedAccount: ProjectStripeConnectedAccount;
}) {
  const { status, id } = stripeConnectedAccount;
  if (status === "Enabled") {
    return null;
  }

  return (
    <section
      className={"px-[20px]"}
      data-testid={`payment-block-content-panel-warning-${blockId}`}
    >
      <div className="flex w-full items-center gap-[20px] rounded-[5px] border border-gray-300 px-[20px] py-[14px]">
        <div
          className={"rounded-[5px] border border-gray-200 bg-zi-white p-[5px]"}
        >
          <IconStripe size={30} />
        </div>
        {status === "Disabled" ? (
          <EditorContentPanelDisabledWarning />
        ) : status === "NeedsMoreInfo" ? (
          <EditorContentPanelNeedsMoreInfoWarning stripeAccountId={id} />
        ) : (
          <EditorContentPanelPendingVerificationWarning />
        )}
      </div>
    </section>
  );
}

function EditorContentPanelPendingVerificationWarning() {
  return (
    <div className={"flex flex-col"}>
      <Text type="paragraph3Bold" as="h3">
        {TitleForStatus["PendingVerification"]}
      </Text>
      <Text
        type="smallPrint1"
        as="p"
        data-testid="stripe-pending-verification-explainer"
      >
        The Stripe Payment block will not be visible on the page until the
        verification is finished.
      </Text>
    </div>
  );
}

function EditorContentPanelNeedsMoreInfoWarning({
  stripeAccountId,
}: {
  stripeAccountId: string;
}) {
  return (
    <div className={"flex flex-col"}>
      <Text type="paragraph3Bold" as="h3">
        {TitleForStatus["NeedsMoreInfo"]}
      </Text>

      <Text type="smallPrint1" as="p">
        Your account is active and ready to accept payments.{" "}
        <Link
          href={getStripeDashboardUrlForAccount({ stripeAccountId })}
          target={"_blank"}
        >
          Go to Stripe
        </Link>{" "}
        to fill in missing information to ensure your account remains in good
        standing.
      </Text>
    </div>
  );
}

function EditorContentPanelDisabledWarning() {
  const { navigateToStripeOnboarding, StripeTermsModal, isLoading } =
    useStripeOnboarding({
      onError: () => {
        toast.error("Failed to perform the action. Please try again.");
      },
    });

  return (
    <div className={"flex flex-col"}>
      {StripeTermsModal}
      <Text type="paragraph3Bold" as="h3">
        {TitleForStatus["Disabled"]}
      </Text>
      {/*The `<Link component="button"` does not accept the `type` prop.

        Since, by default, all buttons are "submit", if we did not wrap the `Link` with a form,
        we would be risking submitting a form somewhere else in the page.

        To ensure this never happens, we wrapped the whole thing in the `form` to prevent the default behavior.
        */}
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          if (isLoading) {
            return null;
          }
          navigateToStripeOnboarding();
        }}
      >
        <div className={"flex gap-2"}>
          <Link component={"button"} style={{ opacity: isLoading ? 0.5 : 1 }}>
            Connect your account
          </Link>
          {isLoading ? <Spinner size={"small"} /> : null}
        </div>
      </form>
    </div>
  );
}

function useStripeOnboarding({ onError }: { onError: VoidFunction }) {
  const { mutateAsync: createAccountLink } =
    trpc.payments.createAccountLink.useMutation({
      useErrorBoundary: false,
      meta: { noToast: true },
    });

  const { mutateAsync: createConnectedAccount } =
    trpc.payments.createConnectedAccount.useMutation({
      useErrorBoundary: false,
      meta: { noToast: true },
    });

  const { fetch: fetchStripeConnectedAccount } =
    useFetchStripeConnectedAccount();

  const { openStripeTermsModal, StripeTermsModal } = useStripeTermsModal();

  const { mutate: navigateToStripeOnboarding, isLoading } = useMutation({
    useErrorBoundary: false,
    retry: false,
    meta: { noToast: true },
    onError: () => {
      onError();
    },
    mutationFn: async () => {
      const connectedAccount = await fetchStripeConnectedAccount();

      if (connectedAccount) {
        const { url } = await createAccountLink();
        return window.open(url, "_blank");
      }

      const stripeTermsMetadata = await openStripeTermsModal();
      if (!stripeTermsMetadata) {
        return;
      }

      await createConnectedAccount({
        termsVersion: stripeTermsMetadata.termsVersion,
        termsUrl: stripeTermsMetadata.termsUrl,
        termsDate: stripeTermsMetadata.termsDate,
      });

      const { url } = await createAccountLink();
      window.open(url, "_blank");
    },
  });

  return {
    isLoading,
    navigateToStripeOnboarding,
    StripeTermsModal,
  };
}

function useFetchStripeConnectedAccount() {
  const utils = trpc.useUtils();

  const getConnectedAccountNumFetches = useIsFetching(
    getQueryKey(trpc.payments.getConnectedAccount, undefined, "query")
  );
  const isLoading = getConnectedAccountNumFetches > 0;

  const fetch = useCallback(async () => {
    try {
      return await utils.payments.getConnectedAccount.fetch(undefined, {
        meta: { noToast: true },
      });
    } catch (error) {
      if (!isConnectedAccountNotFoundError(error)) {
        throw error;
      }

      return null;
    }
  }, [utils.payments.getConnectedAccount]);

  return {
    fetch,
    isLoading,
  };
}

function useStripeTermsModal() {
  const [termsState, setTermsState] = useState<null | {
    acceptTerms: (val: unknown) => void;
    declineTerms: () => void;
  }>(null);

  const openStripeTermsModal = useCallback(async () => {
    let acceptTerms: (val: unknown) => void = () => {};
    let declineTerms: (() => void) | null = () => {};

    try {
      const waitForTerms = new Promise((resolve, reject) => {
        acceptTerms = resolve;
        declineTerms = reject;
      });

      setTermsState({ declineTerms, acceptTerms });

      await waitForTerms;

      setTermsState(null);

      return {
        termsVersion: STRIPE_TERMS.version,
        termsUrl: STRIPE_TERMS.url,
        termsDate: STRIPE_TERMS.date,
      };
    } catch {
      setTermsState(null);

      return null;
    }
  }, []);

  return {
    StripeTermsModal: termsState ? (
      <StripeTermsModal
        onAcceptTerms={() => termsState.acceptTerms(undefined)}
        onDeclineTerms={() => termsState.declineTerms()}
      />
    ) : null,
    openStripeTermsModal,
  };
}
