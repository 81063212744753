import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { isFunction } from "lodash";
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useMemo,
  type ComponentProps,
} from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { cn } from "utils/cn";

export type Props = {
  value?: boolean;
  label?: string;
  text: string;
  defaultChecked: boolean;
  required?: boolean;
  onChange?: (value: boolean) => void;
  name?: string;
  isErrored?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      value,
      label: labelText,
      text,
      defaultChecked,
      required,
      onChange,
      isErrored = false,
      ...props
    },
    ref
  ) => {
    useEffect(() => {
      if (value === undefined && isFunction(onChange)) onChange(false);
    }, [value, onChange]);

    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (e: ChangeEvent<HTMLInputElement>) => {
            onChange(e.target.checked);
          }
        : undefined;
    }, [onChange]);

    const interfacesTheme = useInterfacesTheme();

    return (
      <div
        className={cn(
          "-ml-0.5 flex min-h-fit flex-nowrap items-center gap-x-2.5 text-[17px]",
          {
            "rounded-lg text-zi-text": !interfacesTheme,
            "rounded-large text-primary-foreground": interfacesTheme,
          }
        )}
      >
        <label
          data-errored={isErrored}
          className={cn("flex cursor-pointer items-center gap-1 text-base", {
            "text-zi-text data-[errored=true]:text-zi-formError":
              !interfacesTheme,
            "text-foreground data-[errored=true]:text-destructive":
              interfacesTheme,
          })}
        >
          <CheckboxInput
            ref={ref}
            name={name}
            checked={value ?? defaultChecked}
            onChange={handleChange}
            isErrored={isErrored}
            {...props}
          />
          {text}{" "}
          {required && !labelText ? (
            <span
              className={cn("ml-0.5 font-normal", {
                "text-zi-secondaryText": !interfacesTheme,
                "text-muted-foreground": interfacesTheme,
              })}
            >
              (required)
            </span>
          ) : null}
        </label>
      </div>
    );
  }
);

Checkbox.displayName = "Checkbox";

export const CheckboxInput = forwardRef<
  HTMLInputElement,
  Omit<ComponentProps<"input">, "type"> & {
    isErrored: boolean;
    isRequired?: boolean;
    isDisabled?: boolean;
    containerClassName?: string;
  }
>(
  (
    {
      className,
      isErrored,

      /**
       * We do not use those, but we can't spread them over the input element,
       * because that would case the "does not recognize the `isRequired` prop on a DOM element" error.
       */
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isRequired,

      isDisabled,
      ...restOfProps
    },
    ref
  ) => {
    const interfacesTheme = useInterfacesTheme();
    const checkboxIconBaseStyles = cn(
      "peer-disabled:cursor-not-allowed peer-disabled:opacity-50",
      {
        "text-zi-gray data-[errored=true]:text-zi-formError data-[errored=false]:peer-focus-within:text-zi-primary":
          !interfacesTheme,
        "rounded-small text-muted-foreground ring-background ring-offset-background data-[errored=true]:text-destructive data-[errored=false]:peer-focus-within:ring-2 data-[errored=false]:peer-focus-within:ring-ring":
          interfacesTheme,
      }
    );

    return (
      <div>
        <input
          {...restOfProps}
          disabled={restOfProps.disabled ?? isDisabled}
          className={cn(
            className,
            "peer absolute h-px w-px overflow-hidden whitespace-nowrap [clip-path:inset(50%)] [clip:rect(0_0_0_0)]"
          )}
          ref={ref}
          type="checkbox"
        />
        {/* We are using classes to hide / show different states of the checkbox,
      to allow for the checkbox to be uncontrolled (the caller does not have to provide "value" prop) */}
        <span
          aria-hidden={true}
          data-errored={isErrored}
          className={cn(checkboxIconBaseStyles, "hidden peer-checked:block", {
            "data-[errored=false]:peer-checked:text-zi-primary":
              !interfacesTheme,
            "data-[errored=false]:peer-checked:text-primary": interfacesTheme,
          })}
        >
          <MdCheckBox size={28} />
        </span>
        <span
          aria-hidden={true}
          data-errored={isErrored}
          className={cn(checkboxIconBaseStyles, "block peer-checked:hidden")}
        >
          <MdCheckBoxOutlineBlank size={28} />
        </span>
      </div>
    );
  }
);

CheckboxInput.displayName = "CheckboxInput";
