import { forwardRef, useCallback, useMemo } from "react";
import { Option, UseChoicesProps } from "./types";
import { useChoices } from "./useChoices";
import {
  DynamicDropdownSelect as PresentationalDynamicDropdownSelect,
  Props as DynamicDropdownSelectProps,
} from "./DynamicDropdownSelect";

type Props = DynamicDropdownSelectProps & UseChoicesProps;

export const DynamicDropdownSelect = forwardRef<any, Props>((props, ref) => {
  const mappedOptions = useChoices({
    ...props,
  });

  const getMappedOptionFromValue = useCallback(
    (value: unknown): Option | undefined => {
      if (typeof value === "string") {
        return mappedOptions.find((option) => option.value === value);
      } else if (value && typeof value === "object" && "value" in value) {
        return mappedOptions.find((option) => option.value === value.value);
      }
      return undefined;
    },
    [mappedOptions]
  );

  const onChange = useCallback(
    (selectedOption: Option | Option[] | null) => {
      if (!props.onChange || !selectedOption) return;
      if (props.multiSelect && Array.isArray(selectedOption)) {
        props.onChange?.(
          selectedOption.reduce((acc, value) => {
            const opt = getMappedOptionFromValue(value);
            if (opt) acc.push(opt);
            return acc;
          }, [] as Option[])
        );
      } else if (!Array.isArray(selectedOption)) {
        const opt = getMappedOptionFromValue(selectedOption);
        if (opt) {
          props.onChange?.(opt);
        }
      }
    },
    [getMappedOptionFromValue, props]
  );

  const parsedValue = useMemo<string | string[] | undefined>(() => {
    if (!props.value) {
      return undefined;
    }

    if (props.multiSelect) {
      let parsedValue = props.value;

      if (typeof props.value === "string") {
        parsedValue = props.value.split(",");
      }

      return (parsedValue as Array<string | Option>)
        .reduce((acc, value) => {
          const opt = getMappedOptionFromValue(value);
          if (opt) acc.push(opt);
          return acc;
        }, [] as Option[])
        .map((option) => option.value);
    }

    return getMappedOptionFromValue(props.value)?.value;
  }, [getMappedOptionFromValue, props.multiSelect, props.value]);

  return (
    <PresentationalDynamicDropdownSelect
      {...props}
      ref={ref}
      options={mappedOptions}
      onChange={onChange}
      value={parsedValue}
    />
  );
});

DynamicDropdownSelect.displayName = "DynamicDropdownSelect";
