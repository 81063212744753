import { LabeledRadio, Field, TextInput, Link } from "@zapier/design-system";
import type { FieldBlockFileUpload } from "../../types";
import { FieldGroup } from "components/BlockEditor/components";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { FILE_EXTENSIONS_WHITELIST } from "server/services/uploads/FILE_UPLOAD_WHITELIST";
import {
  ADVANCED_FILE_SIZE,
  FREE_FILE_SIZE,
  PREMIUM_FILE_SIZE,
} from "../../Block";
import { useUser } from "lib/context/user-context";
import { useAccountPlan } from "lib/hooks/useAccountPlan";
import {
  getInterfacesAdvancedBillingUrl,
  getInterfacesPremiumBillingUrl,
} from "lib/route-helpers";

type Props = {
  config: FieldBlockFileUpload;
  handleChange: <T>(newConfig: Partial<T>) => void;
  handleInputChange: <T>(
    name: keyof T
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  errors: Set<string>;
  setErrors: Dispatch<SetStateAction<Set<string>>>;
};

export const allowedFileTypesConfigToArray = (fileTypes: string) => {
  return fileTypes
    .split(",")
    .map((ext) => ext.toLowerCase().split(".").pop() ?? "")
    .filter((ext) => ext !== "");
};

const getUnsupportedFileErrorMessage = (fileTypes?: string) => {
  if (!fileTypes) return;

  const unsupportedTypes: string[] = [];
  const userAllowedFileTypes = allowedFileTypesConfigToArray(fileTypes);
  userAllowedFileTypes.forEach(
    (ext) =>
      !FILE_EXTENSIONS_WHITELIST.includes(ext) && unsupportedTypes.push(ext)
  );

  return `Unsupported file types: ${unsupportedTypes.join(",")}`;
};

export default function FileUploadFields(props: Props) {
  const { user } = useUser();
  const { isAdvanced, isPremium, isFree } = useAccountPlan(user);

  const validateFileTypes = () => {
    if (!props.config.allowedFileTypes) return;
    const userAllowedFileTypes = allowedFileTypesConfigToArray(
      props.config.allowedFileTypes
    );

    if (
      userAllowedFileTypes.some(
        (ext) => !FILE_EXTENSIONS_WHITELIST.includes(ext)
      )
    ) {
      props.setErrors(new Set(props.errors).add("allowedFileTypes"));
    } else {
      const errors = new Set(props.errors);
      errors.delete("allowedFileTypes");
      props.setErrors(errors);
    }
  };

  const renderHelpText = () => {
    if (isFree())
      return (
        <>
          <span>Your plan allows up to 5MB file uploads. </span>
          <Link href={getInterfacesPremiumBillingUrl(window.location.href)}>
            Upgrade
          </Link>
        </>
      );
    if (isPremium())
      return (
        <>
          <span>Your plan allows up to 10MB file uploads. </span>
          <Link href={getInterfacesAdvancedBillingUrl(window.location.href)}>
            Upgrade
          </Link>
        </>
      );

    return null;
  };

  return (
    <>
      <FieldGroup>
        <Field
          label="Max File Size"
          renderHelpText={renderHelpText}
          renderInput={() => (
            <>
              <LabeledRadio
                checked={
                  props.config.allowedFileSize === FREE_FILE_SIZE ||
                  !props.config.allowedFileSize
                }
                name="radio"
                onChange={() =>
                  props.handleChange({
                    allowedFileSize: FREE_FILE_SIZE,
                  })
                }
                tabIndex={1}
              >
                5MB
              </LabeledRadio>
              <LabeledRadio
                disabled={!isPremium() && !isAdvanced() && !user?.isStaff}
                checked={props.config.allowedFileSize === PREMIUM_FILE_SIZE}
                name="radio"
                onChange={() =>
                  props.handleChange({ allowedFileSize: PREMIUM_FILE_SIZE })
                }
                tabIndex={2}
              >
                {isPremium() || isAdvanced() || user?.isStaff
                  ? "10MB"
                  : "10MB - Pro Plan Required"}
              </LabeledRadio>
              <LabeledRadio
                disabled={!isAdvanced() && !user?.isStaff}
                checked={props.config.allowedFileSize === ADVANCED_FILE_SIZE}
                name="radio"
                onChange={() =>
                  props.handleChange({ allowedFileSize: ADVANCED_FILE_SIZE })
                }
                tabIndex={3}
              >
                {isAdvanced() || user?.isStaff
                  ? "25MB"
                  : "25MB - Advanced Plan Required"}
              </LabeledRadio>
            </>
          )}
        />
      </FieldGroup>
      <FieldGroup>
        <Field
          error={
            props.errors.has("allowedFileTypes")
              ? getUnsupportedFileErrorMessage(props.config.allowedFileTypes)
              : undefined
          }
          label="Allowed File Types"
          renderInput={(inputProps) => (
            <TextInput
              {...inputProps}
              onChange={props.handleInputChange("allowedFileTypes")}
              value={props.config.allowedFileTypes ?? ""}
              onBlur={() => validateFileTypes()}
            />
          )}
          renderHelpText={() =>
            "Separate with commas. Allowed types are .jpg, .png, .pdf, .doc, .docx ..etc"
          }
        />
      </FieldGroup>
    </>
  );
}
