import { Icon } from "@zapier/design-system";
import { Wrapper } from "./components";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

export function StandardBlockErrorState({
  title = "Something is amiss",
  description = "This component is not configured appropriately.",
  className,
}: {
  title?: string;
  description?: string;
  className?: string;
}) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <Wrapper className={cn("flex-col items-center p-12", className)}>
      <div
        className={cn("flex h-20 w-20 items-center justify-center", {
          "rounded-full bg-zi-pageBackground text-zi-primary": !interfacesTheme,
          "rounded-large bg-background text-primary-foreground":
            interfacesTheme,
        })}
      >
        <Icon name="alertTriangle" color="currentColor" size={34} isBlock />
      </div>
      <div className="mb-3 mt-5 text-base font-bold">{title}</div>
      <div className="text-sm">{description}</div>
    </Wrapper>
  );
}
