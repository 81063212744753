import React from "react";

import { FormLabelRequiredText } from "./FormLabelRequiredText";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

type Props = {
  /**
   * How to vertically align the items.
   */
  alignItems?: "center" | "start";
  /**
   * How to horizontally justify the content.
   */
  justifyContent?: "start" | "stretch";
  /**
   * The content to render within `FormLabel`. May be text or nodes containing
   * text, an `input`, etc.
   */
  children: React.ReactNode;
  /**
   * The `for` attribute to attach to the `label` node, associating it to
   * an external input by its `id`.
   */
  htmlFor?: string;
  /**
   * Indicates whether the input the `FormLabel` controls is disabled.
   */
  isDisabled?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is errored.
   */
  isErrored?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is required.
   */
  isRequired?: boolean;
  /**
   * Indicates whether the input the `FormLabel` controls is selected.
   * Typically used in conjunction with `BooleanInput`.
   */
  isSelected?: boolean;
  /**
   * Optional text to render inside the required label.
   */
  requiredText?: React.ReactNode;
  /**
   * Size of the `FormLabel`.
   */
  size?: "small" | "medium";
};

const defaultProps = {
  alignItems: "center",
  justifyContent: "start",
  size: "medium",
};

/**
 * `FormLabel` associates text to input fields.
 */
export const FormLabel = (_props: Props) => {
  const props = {
    ...defaultProps,
    ..._props,
  };
  const interfacesTheme = useInterfacesTheme();
  return (
    <label
      className={cn(
        "grid auto-cols-auto grid-flow-col items-start justify-start gap-[5px] text-base font-semibold",
        {
          "text-zi-text [&[data-state~='errored']]:text-zi-formError":
            !interfacesTheme,
          "text-foreground [&[data-state~='errored']]:text-destructive":
            interfacesTheme,
        }
      )}
      data-align-items={props.alignItems}
      data-justify-content={props.justifyContent}
      data-size={props.size}
      data-state={
        [
          props.isSelected && "selected",
          props.isErrored && "errored",
          props.isDisabled && "disabled",
        ]
          .filter(Boolean)
          .join(" ") || undefined
      }
      htmlFor={props.htmlFor}
    >
      {props.children}
      {props.isRequired ? (
        props.requiredText ? (
          <FormLabelRequiredText>{props.requiredText}</FormLabelRequiredText>
        ) : (
          <FormLabelRequiredText />
        )
      ) : null}
    </label>
  );
};
