import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { cn } from "utils/cn";

export function FormError(props: React.ComponentPropsWithoutRef<"div">) {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "font-sans text-sm",
        {
          "text-destructive": interfacesTheme,
          "text-zi-formError": !interfacesTheme,
        },
        props.className
      )}
    />
  );
}
