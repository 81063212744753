import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import { CountryCode } from "libphonenumber-js/types";
import { forwardRef } from "react";
import PhoneInput, { Value } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { cn } from "utils/cn";

const Wrapper: React.FunctionComponent<
  React.ComponentPropsWithRef<"div"> & {
    $isErrored?: boolean;
  }
> = ({ $isErrored, ...props }) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "custom-phoneinput flex h-[50px] flex-row flex-nowrap items-center gap-x-2.5 overflow-hidden px-[15px] py-0 text-[17px] transition-all duration-150",
        {
          "interfaces-theme rounded-large border-input bg-card text-card-foreground focus-within:border-primary":
            interfacesTheme,
          "rounded-lg border-2 border-zi-lightGray bg-zi-superLightGray text-zi-text focus-within:border-zi-primary":
            !interfacesTheme,
          "border-destructive": interfacesTheme && $isErrored,
          "border-zi-formError": !interfacesTheme && $isErrored,
        },
        props.className
      )}
    />
  );
};

export type Props = {
  value?: string;
  onChange?: (value: Value | undefined) => void;
  defaultCountry: CountryCode;
  placeholder?: string;
  isDisabled?: boolean;
  isErrored?: boolean;
};

export const PhoneNumberInput = forwardRef<HTMLDivElement, Props>(
  (
    { onChange, value, defaultCountry, placeholder, isDisabled, isErrored },
    ref
  ) => {
    return (
      <Wrapper $isErrored={isErrored} ref={ref}>
        <PhoneInput
          flags={flags}
          placeholder={placeholder}
          defaultCountry={defaultCountry}
          value={value}
          onChange={(value) => {
            onChange?.(value);
          }}
          disabled={isDisabled}
        />
      </Wrapper>
    );
  }
);

PhoneNumberInput.displayName = "PhoneNumberInput";
