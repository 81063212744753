import { BlockAlert } from "block-system/blocks/__shared__/Alert";
import { Button, Field, FormContainer } from "block-system/components";
import { FocusWrapper } from "block-system/components/FocusWrapper";
import { BlockId } from "block-system/types";
import Blocks from "components/Blocks";
import { ReCAPTCHA } from "components/ReCAPTCHA";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";
import React from "react";
import { SubmitHandler, useFormContext } from "react-hook-form";
import { MdInfo } from "react-icons/md";
import { cn } from "utils/cn";
import { FormData } from ".";
import { FormBlock as FormBlockType } from "../schema";

export function Form({
  onSubmit,
  isDisabled,
  config,
  fieldBlocksToRender,
  blockId,
  actionIsFocused,
  setRecaptchaToken,
  setRecaptchaKey,
  isEditing,
  isPaidUser,
}: {
  onSubmit: SubmitHandler<FormData>;
  isDisabled: boolean;
  config: FormBlockType["config"];
  fieldBlocksToRender: FormBlockType["children"];
  blockId: BlockId;
  actionIsFocused: boolean;
  setRecaptchaToken: (token: string) => void;
  setRecaptchaKey: (key: number) => void;
  isEditing: boolean;
  isPaidUser: boolean;
}) {
  const methods = useFormContext();

  // Customers have requested that pressing enter in a form field should not submit the form
  const preventFormSubmissionOnEnter = (
    e: React.KeyboardEvent<HTMLFormElement>
  ) => {
    const target = e.target;
    if (e.key === "Enter" && target instanceof HTMLInputElement) {
      e.preventDefault();
    }
  };

  const renderPhishingWarning = !isPaidUser;

  return (
    <form
      aria-label="form"
      onSubmit={methods.handleSubmit(onSubmit)}
      onKeyDown={preventFormSubmissionOnEnter}
    >
      <FormContainer as="fieldset" disabled={isDisabled}>
        <Blocks blocks={fieldBlocksToRender} bare parentId={blockId} />

        {config.captchaEnabled ? (
          <Field
            error={methods.formState.errors.captcha?.message?.toString()}
            label="Captcha (required)"
            renderInput={() => (
              <ReCAPTCHA
                action="SUBMIT_FORM_BLOCK"
                onChange={(token) => {
                  setRecaptchaToken(token);
                }}
                onRendered={(key) => {
                  setRecaptchaKey(key);
                }}
              />
            )}
          />
        ) : null}

        {renderPhishingWarning ? (
          <PhishingWarning isEditing={isEditing} />
        ) : null}

        <FocusWrapper isFocused={actionIsFocused} focusedBlockType="Button">
          <Button type="submit" isLoading={methods.formState.isSubmitting}>
            {config.submitText}
          </Button>
        </FocusWrapper>
      </FormContainer>
    </form>
  );
}

function PhishingWarning({ isEditing }: { isEditing: boolean }) {
  const interfacesTheme = useInterfacesTheme();

  return (
    <BlockAlert
      data-testid={"form-phishing-warning"}
      title={isEditing ? "Upgrade to remove this message" : undefined}
      icon={
        <MdInfo
          size={20}
          className={cn({
            "text-zi-secondaryText": !interfacesTheme,
            "text-muted-foreground": interfacesTheme,
          })}
        />
      }
      description="Please avoid sharing sensitive data such as passwords or credit card info in this form. This is not a secure login or billing page to share that data."
    />
  );
}
