import { useTheme } from "lib/theme";
import BlockWrapper from "block-system/components/BlockWrapper";
import { usePageContext } from "lib/context/page-context";
import { useEffect, useRef, useState, useMemo } from "react";

import {
  getDatasourceDependencies,
  useContentDatasource,
} from "lib/hooks/useContentDatasource";
import type { MarkdownTextBlock as MarkdownTextBlockType } from "../types";
import type { BlockProps } from "../../__shared__/types";
import { useHandlebarParser } from "lib/hooks/useHandlebarParser";
import { cn } from "utils/cn";
import { MarkdownText } from "./MarkdownText";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

export const MarkdownTextBlock = (props: BlockProps<MarkdownTextBlockType>) => {
  const { config } = props.block;
  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const { isEditing } = usePageContext();

  const supportedDynamicFields = useMemo(() => ["content"], []);
  const datasourceDependencies = useMemo(
    () => getDatasourceDependencies(config, supportedDynamicFields),
    [config, supportedDynamicFields]
  );

  const { data: contentDatasource } = useContentDatasource(
    datasourceDependencies
  );
  const parsedConfig = useHandlebarParser(
    config,
    contentDatasource,
    supportedDynamicFields
  );

  const centered = parsedConfig.textAlign === "center";

  const interfacesTheme = useInterfacesTheme();

  useEffect(() => {
    if (ref.current) {
      setIsEmpty(!ref.current.innerText);
    }
  }, [parsedConfig.content]);

  return (
    <BlockWrapper
      blockId={props.blockId}
      block={props.block}
      maxWidth={
        parsedConfig.style?.width
          ? theme.app.width[parsedConfig.style?.width]
          : theme.app.width.narrow
      }
      alignment={parsedConfig.style?.alignment}
    >
      <MarkdownText {...props.block.config} {...parsedConfig} />
      {isEditing && isEmpty ? (
        <p
          className={cn({
            "text-muted-foreground": interfacesTheme,
            "text-zi-placeholder": !interfacesTheme,
            "text-center": centered,
          })}
        >
          Empty text block
        </p>
      ) : null}
    </BlockWrapper>
  );
};
