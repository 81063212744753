import { ReactNode, Suspense, useCallback } from "react";
import { trim } from "lodash";
import { BlockId, ContentBlock } from "block-system/types";
import { DateFormatOptions, PhoneNumberFormatOptions } from "../schema";
import type { FieldBlock, FieldBlock as FieldBlockType } from "../types";
import {
  Field,
  TextInput,
  TextArea,
  YesNoInput,
  UrlInput,
  DatePicker,
  DropdownSelect,
  PhoneNumberInput,
  Checkbox,
  FileUpload,
  Currency,
  DynamicDropdownSelect,
  BlockWrapper,
} from "block-system/components";
import { buildValidationRules } from "./utils";
import { Controller, useFormContext } from "react-hook-form";
import { Spinner } from "block-system/components/Spinner";
import ReactMarkdown from "react-markdown";
import { useContentDatasource } from "lib/hooks/useContentDatasource";
import { isDynamicVariable } from "../utils";
import { parseHandlebars } from "lib/utils/parseHandlebars";
import { usePageContext } from "lib/context/page-context";
import { isConditionallyRendered } from "../lib/conditional-logic/conditional-logic";
import { useCurrentProject } from "lib/context/current-project-context";
import { useFeatureAccessCheck } from "lib/hooks/useFeatureAccessCheck";
import { FormLabel } from "block-system/components/forms/FormLabel";
import { ConditionalRenderBadge } from "block-system/components/ConditionalRenderBadge";
import { AIFormula } from "block-system/components/forms/AIFormula";
import { Props as FieldProps } from "block-system/components/forms/Field";
import { LinkedRecordFields } from "block-system/components/forms/LinkedRecordFields";

const MEGABYTE = 1024 * 1024;

export const FREE_FILE_SIZE = 5 * MEGABYTE;
export const PREMIUM_FILE_SIZE = 10 * MEGABYTE;
export const ADVANCED_FILE_SIZE = 25 * MEGABYTE;

export function FieldBlock(props: {
  block: FieldBlockType;
  blocks: ContentBlock[];
  blockId: BlockId;
}) {
  const { config } = props.block;
  const { data: datasource } = useContentDatasource();
  const { creator, paidFeatureAccess } = useCurrentProject();

  let maxFileSize = FREE_FILE_SIZE;

  const allowAdvancedFileSize = useFeatureAccessCheck("fileSizeLimit25mb", {
    ...creator,
    paidFeatureAccess,
  });
  const allowPremiumFileSize = useFeatureAccessCheck("fileSizeLimit10mb", {
    ...creator,
    paidFeatureAccess,
  });

  if (allowAdvancedFileSize) {
    maxFileSize = ADVANCED_FILE_SIZE;
  } else if (allowPremiumFileSize) {
    maxFileSize = PREMIUM_FILE_SIZE;
  } else {
    maxFileSize = FREE_FILE_SIZE;
  }

  const { isEditing } = usePageContext();

  const name = config.name;

  const validationRules = buildValidationRules(config);

  const {
    formState: { errors, isSubmitting },
  } = useFormContext();

  const renderMarkdown = useCallback(
    (content: string) => {
      if (!isEditing) content = parseHandlebars(content, datasource);

      return (
        <ReactMarkdown
          allowedElements={[
            "a",
            "p",
            "strong",
            "em",
            "code",
            "pre",
            "li",
            "ol",
            "ul",
          ]}
          linkTarget="_blank"
        >
          {content}
        </ReactMarkdown>
      );
    },
    [datasource, isEditing]
  );

  const renderContent = () => {
    const renderInput = (inputProps: any, controllerProps: any): ReactNode => {
      if (
        !isEditing &&
        config.placeholder &&
        isDynamicVariable(config.placeholder.replace(/\s/g, ""))
      ) {
        config.placeholder = parseHandlebars(config.placeholder, datasource);
      }

      switch (config.inputType) {
        case "text":
        case "number":
          return (
            <TextInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              type="text"
            />
          );
        case "email":
          return (
            <TextInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              type={config.inputType}
            />
          );
        case "url":
          return (
            <UrlInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
            />
          );

        case "textarea":
          return (
            <TextArea
              {...inputProps}
              {...controllerProps}
              rows={3}
              placeholder={config.placeholder}
              name={name}
            />
          );

        case "phone-number":
          return (
            <PhoneNumberInput
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              defaultCountry={config.defaultCountry}
              phoneNumberFormat={
                config.phoneNumberFormat ?? PhoneNumberFormatOptions[0].value
              }
            />
          );

        case "dropdown":
          // make separate DynamicDropdown component that calls Tables API and conditionally render it if this won't be a static dropdown
          // otherwise use static one as below
          if (config.tableId && config.tableFieldId) {
            // return dynamic dropdown
            return (
              <DynamicDropdownSelect
                {...inputProps}
                {...controllerProps}
                placeholder={config.placeholder}
                tableId={config.tableId}
                tableFieldId={config.tableFieldId}
                tablesFieldKey={config.tablesFieldKey}
                name={name}
                multiSelect={config.multiSelect}
              />
            );
          }
          return (
            <DropdownSelect
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              options={getDropdownOptions(config.options)}
              name={name}
              multiSelect={config.multiSelect}
            />
          );

        case "yes-no":
          return (
            <YesNoInput {...inputProps} {...controllerProps} name={name} />
          );

        case "date-picker":
          return (
            <DatePicker
              {...inputProps}
              {...controllerProps}
              name={name}
              dateFormat={config.dateFormat ?? DateFormatOptions[0].value}
              placeholder={config.placeholder}
              includeTime={config.includeTime}
              defaultToNow={config.defaultToNow}
            />
          );

        case "checkbox":
          return (
            <Checkbox
              {...inputProps}
              {...controllerProps}
              label={config.label}
              name={name}
              required={config.required}
              defaultChecked={config.defaultChecked}
              text={config.text}
            />
          );

        case "currency":
          return (
            <Currency
              {...inputProps}
              {...controllerProps}
              placeholder={config.placeholder}
              currencyFormat={config.currencyFormat}
              decimals={config.decimals}
            />
          );

        case "file-upload":
          let allowedFileSize = FREE_FILE_SIZE;

          // This ensures that we never give away more upload size than allowed by the plan
          if (config.allowedFileSize) {
            allowedFileSize = Math.min(config.allowedFileSize, maxFileSize);
          }

          return (
            <FileUpload
              {...inputProps}
              {...controllerProps}
              label={config.label}
              name={name}
              required={config.required}
              placeholder={config.placeholder}
              allowedFileTypes={config.allowedFileTypes}
              maxFileSize={allowedFileSize}
              blockId={props.block.config.id}
              isEditing={isEditing}
            />
          );

        case "ai-formula":
          return <AIFormula value={controllerProps.value?.value || ""} />;

        case "multiple-linked-record":
          return <LinkedRecordFields value={controllerProps.value} />;

        case "linked-record":
          return <LinkedRecordFields value={controllerProps.value} />;

        default:
          const _exhaustiveCheck: never = config;
          return _exhaustiveCheck;
      }
    };

    const customRenderLabel = (): FieldProps["renderLabel"] => {
      if (isEditing && isConditionallyRendered(props.block)) {
        return ConditionallyRenderedLabel(config.label);
      }

      if (config.inputType === "ai-formula") {
        return ReadOnlyLabel(config.label);
      }

      if (config.inputType === "multiple-linked-record") {
        return ReadOnlyLabel(config.label);
      }

      if (config.inputType === "linked-record") {
        return ReadOnlyLabel(config.label);
      }

      return undefined;
    };

    return (
      <Suspense fallback={<Spinner height={20} />}>
        <Controller
          key={config.id}
          name={name}
          rules={validationRules}
          render={({ field: controllerProps }) => (
            <Field
              inputId={config.name}
              label={config.label}
              renderLabel={customRenderLabel()}
              isDisabled={isSubmitting}
              isRequired={config.required}
              renderHelpText={
                config.helpText
                  ? () => renderMarkdown(config.helpText ?? "")
                  : undefined
              }
              error={errors?.[name]?.message as string | undefined}
              renderInput={(inputProps) =>
                renderInput(inputProps, controllerProps)
              }
              hideLabel={!config.label}
            />
          )}
        />
      </Suspense>
    );
  };

  if (config.hidden) {
    return null;
  }

  return (
    <BlockWrapper blockId={props.blockId} block={props.block}>
      {renderContent()}
    </BlockWrapper>
  );
}

export function getDropdownOptions(options?: string) {
  return (
    options
      ?.split("\n")
      .map(trim)
      .filter((option) => !!option)
      .map((option) => ({
        label: option,
        value: option,
      })) ?? []
  );
}

function ConditionallyRenderedLabel(
  labelText: string
): FieldProps["renderLabel"] {
  return function CustomLabel(labelProps) {
    return (
      <FormLabel {...labelProps}>
        <span>{labelText}</span>
        <ConditionalRenderBadge />
      </FormLabel>
    );
  };
}

function ReadOnlyLabel(labelText: string): FieldProps["renderLabel"] {
  return function CustomLabel(labelProps) {
    return (
      <span className="flex items-center">
        <FormLabel {...labelProps}>
          <span>{labelText}</span>
        </FormLabel>
        <div className={"group relative cursor-not-allowed px-1"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            height="18"
            width="18"
            color="neutral600"
          >
            <path
              fill="#95928E"
              d="M17 8V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H4v10h2v-8h12v10H4v2h16V8h-3Zm-2 0H9V6c0-1.65 1.35-3 3-3s3 1.35 3 3v2Z"
            ></path>
            <path
              fill="#95928E"
              d="M12 17.25a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5Z"
            ></path>
          </svg>
        </div>
        <span className="text-sm">(read only)</span>
      </span>
    );
  };
}
