import { TableBackedKanban } from "./TableBackedKanban";
import Skeleton from "react-loading-skeleton";

const KANBAN_SKELETON_DATA = [
  {
    id: "1",
    label: "Card 1",
    cards: [
      {
        id: "card1",
        descriptionFieldType: "text",
        description: "Description",
        title: "Card 1",
      },
      {
        id: "card2",
        descriptionFieldType: "text",
        description: "Description",
        title: "Card 2",
      },
    ],
  },
  {
    id: "2",
    label: "Card 2",
    cards: [
      {
        id: "card1",
        descriptionFieldType: "text",
        description: "Description",
        title: "Card 1",
      },
    ],
  },
  {
    id: "3",
    label: "Card 3",
    cards: [],
  },
];

export function KanbanSkeleton() {
  return (
    <div>
      <Skeleton height={32} width={200} style={{ marginBottom: "24px" }} />
      <TableBackedKanban data={KANBAN_SKELETON_DATA} isSkeleton />
    </div>
  );
}
