import { ContentBlockTypes } from "block-system/types";
import { DividerImages } from "../Divider/images";
import { BlockImages } from "./types";
import { EmbedImages } from "../Embed/images";
import { FieldImages } from "../Form/Field/images";
import { KanbanImages } from "../Kanban/images";
import { FormImages } from "../Form/Form/images";
import { LayoutImages } from "../Layout/images";
import { LinksImages } from "../Links/Links/images";
import { LinksCardImages } from "../Links/LinksCard/images";
import { MarkdownTextImages } from "../MarkdownText/images";
import { MediaImages } from "../Media/images";
import { TableImages } from "../Table/images";
import { ButtonBlockImages } from "../Button/images";
import { ChecklistImages } from "../Checklist/images";
import { ChatbotImages } from "../Chatbot/images";
import { StripePaymentImages } from "../StripePayment/images";

export function getImagesForBlock(blockType: ContentBlockTypes): BlockImages {
  switch (blockType) {
    case "divider-block":
      return DividerImages;
    case "kanban-block":
      return KanbanImages;
    case "media-block":
      return MediaImages;
    case "embed-block":
      return EmbedImages;
    case "table-listing-block":
      return TableImages;
    case "markdown-text-block":
      return MarkdownTextImages;
    case "checklist-block":
      return ChecklistImages;
    case "chatbot-block":
      return ChatbotImages;
    case "layout-block":
      return LayoutImages;
    case "form-block":
      return FormImages;
    case "links-block":
      return LinksImages;
    case "button-block":
      return ButtonBlockImages;
    case "field-block":
      return FieldImages;
    case "links-card-block":
      return LinksCardImages;
    case "stripe-payment-block":
      return StripePaymentImages;
    default:
      const _exhaustiveCheck: never = blockType;
      return _exhaustiveCheck;
  }
}
