import { ColorsCSS as Colors, Typography } from "@zapier/design-system";
import { styled } from "lib/theme";

export { Section, SectionHeader, SectionContent } from "./Section";

export const FieldGroup = styled.div`
  padding: 0 20px 20px;
`;

export const HelpBlock = styled.div`
  padding: 0 20px;
  font-family: ${Typography.base.fontFamily};
  font-size: 12px;
  line-height: 1.5;
  color: ${Colors.neutral600};
`;
