import { BlockAction as DbBlockAction } from "@prisma/client";
import { BlockCreateProps } from "block-system/blocks/__shared__/types";
import {
  create as createField,
  copy as copyFieldBlock,
  map as mapFieldBlock,
} from "../Field/operations";
import { blockType } from "./constants";
import { FormBlock, FormBlockAction, FormBlockSchema } from "./schema";
import { Form } from "./types";
import { blockActionTypeDbToJson } from "server/models/util/blockAction/blockActionTypeDbToJson";
import {
  NavigateActionSchema,
  NotificationActionSchema,
  OpenExternalUrlActionSchema,
} from "block-system/types";

export const copy: Form["copy"] = (block) => ({
  ...block,
  config: {
    ...block.config,
    id: undefined,
    storageCreatedFromInterfaces: undefined,
    triggers: block.config.triggers.map((trigger) => ({
      ...trigger,
      config: { ...trigger.config, id: undefined },
    })) as FormBlockAction[],
  },
  children: block.children.map(copyFieldBlock),
});

export const create: Form["create"] = (props?: BlockCreateProps<FormBlock>) => {
  return FormBlockSchema.parse({
    type: blockType,
    config: {
      ...(props?.initialConfig ?? {}),
      submitText: props?.initialConfig?.submitText ?? "Submit",
      triggers:
        Array.isArray(props?.initialConfig?.triggers) &&
        props?.initialConfig?.triggers.length
          ? props?.initialConfig.triggers
          : [
              {
                type: "notification",
                config: { position: "top-center", message: "Form submitted!" },
              },
            ],
      style: {
        ...props?.initialConfig?.style,
        width: props?.initialConfig?.style?.width ?? "narrow",
        alignment: props?.initialConfig?.style?.alignment ?? "center",
      },
    },
    children: props?.children?.length ? props.children : [createField()],
  });
};

export const map: Form["map"] = (block) => ({
  type: "form-block",
  children: (block.children ?? []).map(mapFieldBlock),
  config: {
    ...FormBlockSchema.shape.config
      .omit({ triggers: true })
      .parse(block.config),
    id: block.id,
    triggers: (block.actions ?? []).map(mapFormAction),
  },
});

export function mapFormAction(action: DbBlockAction): FormBlockAction {
  const actionType = blockActionTypeDbToJson(action.type);
  switch (actionType) {
    case "notification":
      return {
        type: actionType,
        config: {
          ...NotificationActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    case "navigate":
      return {
        type: actionType,
        config: {
          ...NavigateActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    case "openExternalUrl":
      return {
        type: actionType,
        config: {
          ...OpenExternalUrlActionSchema.shape.config.parse(action.config),
          id: action.id,
        },
      };
    default:
      const _exhaustiveCheck: never = actionType;
      return _exhaustiveCheck;
  }
}
