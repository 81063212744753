import { FileInfo, Widget, WidgetAPI } from "@uploadcare/react-widget";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  MdClose,
  MdImage,
  MdOutlineCloudUpload,
  MdOutlineTextSnippet,
} from "react-icons/md";
import { config } from "server/config";
import {
  DISALLOWED_MIME_TYPES,
  FILE_EXTENSIONS_WHITELIST,
} from "server/services/uploads/FILE_UPLOAD_WHITELIST";
import { allowedFileTypesConfigToArray } from "block-system/blocks/Form/Field/Editor/EditorFields/FileUploadFields";
import { cn } from "utils/cn";
import {
  usePollingUploadStatusQuery,
  useUploadSignatureQuery,
} from "../../../lib/uploads/hooks";
import { usePreviousCallback } from "lib/hooks";
import { useFormState } from "react-hook-form";
import { useSplitFlags } from "lib/context/split-context";
import { Icon } from "@zapier/design-system";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

const Wrapper: React.FunctionComponent<React.ComponentPropsWithRef<"div">> = (
  props
) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={
        // prettier-ignore
        cn(
            "flex h-20 items-center justify-between border-2 border-dashed px-5 py-0 custom-uploadcare-widget",
            {
              "rounded-lg border-zi-lightGray bg-zi-superLightGray": !interfacesTheme,
              "interfaces-theme rounded-large border-border bg-background": interfacesTheme,
            },
            props.className
          )
      }
    />
  );
};

const Placeholder: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"div">
> = (props) => {
  const interfacesTheme = useInterfacesTheme();
  return (
    <div
      {...props}
      className={cn(
        "flex shrink items-center text-base font-normal not-italic",
        {
          "text-zi-darkGray": !interfacesTheme,
          "text-muted-foreground": interfacesTheme,
        },
        props.className
      )}
    />
  );
};

type Props = {
  placeholder?: string;
  value?: FileInfo | null;
  allowedFileTypes?: string;
  onChange: (value?: FileInfo) => void;
  maxFileSize?: number;
  blockId: string;
  isEditing?: boolean;
  id: string;
};

export const FileUpload = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [fileUploadsDisabled] = useSplitFlags([
    "temporarily-disable-file-uploads",
  ]);

  const interfacesTheme = useInterfacesTheme();

  // See INTRFCS-3026 for context
  if (fileUploadsDisabled) {
    return (
      <div
        className={cn("flex gap-2 p-4", {
          "rounded-md bg-zi-primary-50": !interfacesTheme,
          "rounded-medium bg-background": interfacesTheme,
        })}
      >
        <div
          className={cn("flex-none", {
            "text-zi-primary": !interfacesTheme,
            "text-primary": interfacesTheme,
          })}
        >
          <Icon name="alertCircleFill" size={24} isBlock />
        </div>
        <div className="flex-1">
          File uploads are temporarily disabled and will return shortly. We
          apologize for the inconvenience.
        </div>
      </div>
    );
  }

  return <FileUpload_Real ref={ref} {...props} />;
});

const FileUpload_Real = forwardRef<HTMLDivElement, Props>(
  ({ placeholder, ...props }, ref) => {
    const widgetRef = useRef<WidgetAPI>(null);

    const [file, setFile] = useState<FileInfo | undefined>(undefined);
    const [fileSelected, setFileSelected] = useState(false);

    const { isSubmitSuccessful } = useFormState();

    useEffect(() => {
      if (isSubmitSuccessful && file) {
        setFile(undefined);
      }
    }, [file, isSubmitSuccessful]);

    const { data: uploadSignature, refetch: refetchUploadSignature } =
      useUploadSignatureQuery(
        {
          type: "formSubmission",
          blockId: props.blockId,
        },
        {
          enabled: !props.isEditing,
        }
      );

    const { data: uploadStatus, isError: isUploadStatusError } =
      usePollingUploadStatusQuery(file?.uuid);

    usePreviousCallback(uploadStatus, (prev, current) => {
      if (prev?.status !== "success" && current?.status === "success" && file) {
        const reformattedFile: FileInfo = {
          ...file,
          cdnUrl: current.url,
        };

        props.onChange(reformattedFile);
      }
    });

    const acceptTypes = useMemo(() => {
      const defaultAllowList = FILE_EXTENSIONS_WHITELIST.map(
        (ext) => `.${ext}`
      ).join(",");
      if (!props.allowedFileTypes) return defaultAllowList;
      const allowedFileTypes = allowedFileTypesConfigToArray(
        props.allowedFileTypes
      ).filter((ext) => ext !== "*");

      const mimeTypes = allowedFileTypes
        .filter((ext) => FILE_EXTENSIONS_WHITELIST.includes(ext))
        .map((ext) => `.${ext}`);

      return mimeTypes.length ? mimeTypes.join(",") : defaultAllowList;
    }, [props.allowedFileTypes]);

    const fileSizeValidator = useCallback(
      (fileInfo: FileInfo) => {
        if (
          !props.maxFileSize ||
          fileInfo.size === null ||
          fileInfo.sourceInfo?.source === "uploaded"
        ) {
          return;
        }

        if (fileInfo.size > props.maxFileSize) {
          throw new Error("fileMaximumSize");
        }
      },
      [props.maxFileSize]
    );

    const fileExtensionValidator = useCallback(
      (fileInfo: FileInfo) =>
        validateFile({ fileInfo, allowedFileTypes: props.allowedFileTypes }),
      [props.allowedFileTypes]
    );

    const onFileSelect = useCallback(
      (e: any) => {
        setFileSelected(e !== null);
        if (e !== null) {
          e.done((file: FileInfo) => {
            setFile(file);
          });
        } else {
          props.onChange(undefined);
        }
      },
      [props]
    );

    const reset = useCallback(() => {
      setFileSelected(false);
      onFileSelect(null);
      void refetchUploadSignature();
    }, [onFileSelect, refetchUploadSignature]);

    // Placeholder for when we're waiting for the upload signature to load. Skip
    // this in the editor because the signature won't load there, and the UI
    // isn't clickable anyway.
    if (!uploadSignature && !props.isEditing) {
      return (
        <Wrapper>
          <Placeholder>
            <MdOutlineCloudUpload size={28} style={{ marginRight: 10 }} />
            {placeholder || "Drag and drop files here"}
          </Placeholder>
        </Wrapper>
      );
    }

    const renderFileIcon = () => {
      switch (file?.mimeType) {
        case "image/jpg":
        case "image/jpeg":
          return <MdImage size={28} style={{ marginRight: 10 }} />;
        default:
          return <MdOutlineTextSnippet size={28} style={{ marginRight: 10 }} />;
      }
    };

    return (
      <Wrapper ref={ref}>
        <div
          className={cn("flex items-center justify-between", {
            "grow-0": !!file,
            grow: !file,
          })}
        >
          <Placeholder>
            {!fileSelected && (
              <>
                <MdOutlineCloudUpload size={28} style={{ marginRight: 10 }} />
                {placeholder || "Drag and drop files here"}
              </>
            )}

            {file ? renderFileIcon() : null}
          </Placeholder>

          {file && uploadStatus?.status === "pending" ? (
            <Placeholder>Verifying...</Placeholder>
          ) : null}
          {file &&
          (uploadStatus?.status === "failure" || isUploadStatusError) ? (
            <Placeholder>Failed to upload.</Placeholder>
          ) : null}
          {Boolean(!file || uploadStatus?.status === "success") && (
            <Widget
              ref={widgetRef}
              publicKey={config().NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY}
              secureSignature={uploadSignature?.secureSignature}
              secureExpire={
                uploadSignature?.secureExpire
                  ? parseInt(uploadSignature?.secureExpire)
                  : undefined
              }
              metadata={uploadSignature?.metadata}
              onFileSelect={onFileSelect}
              tabs="file camera url facebook gdrive gphotos"
              localeTranslations={{
                buttons: { choose: { files: { one: "Browse files" } } },
                errors: {
                  // @ts-ignore
                  fileType: "File type not supported",
                  fileMaximumSize: "File size exceeds max",
                },
                serverErrors: {
                  SignatureExpirationError:
                    "Upload failed. Please refresh your browser and retry.",
                },
              }}
              value={props.value?.cdnUrl ?? ""}
              inputAcceptTypes={acceptTypes}
              validators={[fileExtensionValidator, fileSizeValidator]}
            />
          )}
        </div>

        {file ? (
          <MdClose size={28} onClick={reset} style={{ cursor: "pointer" }} />
        ) : null}
      </Wrapper>
    );
  }
);

FileUpload.displayName = "FileUpload";
FileUpload_Real.displayName = "FileUpload_Real";

export function validateFile({
  fileInfo,
  allowedFileTypes,
}: {
  fileInfo: FileInfo;
  allowedFileTypes: Props["allowedFileTypes"];
}) {
  if (fileInfo.sourceInfo?.source === "uploaded") {
    return;
  }

  const ext = fileInfo.name?.split(".").pop()?.toLowerCase();
  const isDisabledMimeType = DISALLOWED_MIME_TYPES.includes(
    fileInfo.mimeType ?? ""
  );

  const computedAllowedFileTypes = allowedFileTypes
    ? allowedFileTypesConfigToArray(allowedFileTypes).filter(
        (ext) => ext !== "*"
      )
    : FILE_EXTENSIONS_WHITELIST;

  if (!ext || !computedAllowedFileTypes.includes(ext) || isDisabledMimeType) {
    throw new Error("fileType");
  }
}
