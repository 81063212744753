import partition from "lodash/partition";
import { APIField, APIFieldType } from "server/services/tables/types/fields";
import type { Table } from "../types";
import type { APIFieldLatest } from "server/services/tables/types/tables-latest";

export const SUPPORTED_TABLES_FIELD_TYPES: APIFieldType[] = [
  "string",
  "multiple_string",
  "labeled_string",
  "multiple_labeled_string",
  "text",
  "boolean",
  "number",
  "decimal",
  "email",
  "link",
  "datetime",
  "currency",
  "phone_number",
  "button_trigger_zap",
  "button_continue_zap",
  "ai_formula",
  "multiple_linked_record",
  "linked_record",
];

export const filterUnsupportedTableFields: Table["filterUnsupportedTableFields"] =
  (fields) => {
    if (!fields) return [undefined, undefined];

    return partition(fields, (field) => {
      return SUPPORTED_TABLES_FIELD_TYPES.includes(
        (field as APIField | APIFieldLatest).type
      );
    });
  };
