export const FILE_EXTENSIONS_WHITELIST = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "webp",
  "bmp",
  "tiff",
  "ico",
  "eps",
  "ps",
  "pdf",
  "doc",
  "docx",
  "csv",
  "xls",
  "xlsx",
  "txt",
  "ppt",
  "pptx",
  "psd",
  "ttf",
  "otf",
  "mp3",
  "mp4",
  "ogg",
  "opus",
  "wav",
  "mp4",
  "m4v",
  "mkv",
  "webm",
  "mov",
  "wmv",
  "avi",
  "mpg",
  "ogv",
  "3gp",
  "3g2",
  "zip",
  "xml",
];

// Diallowing SVG files because they can contain stored XSS
export const DISALLOWED_MIME_TYPES = ["image/svg+xml"];
