import { useMemo } from "react";
import { trpc } from "utils/trpc";
import { UseChoicesProps, Option } from "./types";

export function useChoices({
  tableId,
  tableFieldId,
  tablesFieldKey,
}: UseChoicesProps): Option[] {
  const choices = tablesFieldKey
    ? trpc.tableFields.getChoices.useQuery(
        {
          fieldId: tableFieldId,
          tableId: tableId,
        },
        {
          enabled: !!tableFieldId,
          /**
           * The number is arbitrary.
           * We have added it in attempt to reduce calls to the Tables API.
           */
          staleTime: 20_000,
        },
      )
    : trpc.tableRecords.getChoicesByFieldId.useQuery(
        {
          fieldId: tableFieldId,
          tableId: tableId,
        },
        {
          enabled: !!tableFieldId,
          /**
           * The number is arbitrary.
           * We have added it in attempt to reduce calls to the Tables API.
           */
          staleTime: 20_000,
        },
      );

  return useMemo(() => {
    return (
      choices.data?.data
        ?.map(
          (choice) =>
            ({
              label: choice.label,
              value: choice.value,
            }) as Option,
        )
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [choices.data?.data]);
}
