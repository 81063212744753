import { trpc } from "utils/trpc";
import React, { ReactNode } from "react";
import { TRPCClientError } from "@trpc/client";
import { InformativeBlockErrorState } from "block-system/components/BlockErrors/InformativeBlockErrorState";

import * as Sentry from "@sentry/nextjs";

export function TablePermissionErrorBoundary({
  tableId,
  isEditing,
  children,
}: {
  isEditing: boolean;
  tableId: string | undefined;
  children: ReactNode;
}) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        // If we're in the editor and the error is a permissions error, show a
        // special error state to help the builder fix the issue. Otherwise,
        // rethrow the error to let the standard block error state render.
        if (
          isEditing &&
          error instanceof TRPCClientError &&
          error.data?.code === "tables:permissions:errors"
        ) {
          return (
            <InformativeBlockErrorState
              title="Data not available"
              description="This component is connected to a Table you don't have access to."
              iconBefore="navTables"
              iconAfter="formAdd"
            />
          );
        } else {
          throw error;
        }
      }}
    >
      <ConnectedTablePermission tableId={tableId} />
      {children}
    </Sentry.ErrorBoundary>
  );
}
// Fetch the connected table to confirm that this form is able to submit to
// it. No data is used from this query. The intent is that if the query fails,
// it will throw the appropriate error.
function ConnectedTablePermission({
  tableId,
}: {
  tableId: string | undefined;
}) {
  trpc.tableTables.get.useQuery(
    { tableId: tableId as string },
    {
      suspense: false,
      enabled: !!tableId,
    }
  );

  return null;
}
