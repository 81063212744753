import { forwardRef, useEffect, useMemo, useState } from "react";
import {
  IconOptionCircleChecked,
  IconOptionCircleUnchecked,
  IconOptionSquareChecked,
  IconOptionSquareUnchecked,
} from "../icons";
import { cn } from "utils/cn";
import { useInterfacesTheme } from "lib/theme/ThemeProvider";

const Label: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"label"> & {
    interfacesTheme?: boolean;
  }
> = ({ interfacesTheme, ...props }) => (
  <label
    {...props}
    className={cn(
      "text-base font-semibold",
      {
        "text-zi-text": !interfacesTheme,
        "text-foreground": interfacesTheme,
      },
      props.className
    )}
  />
);

const OptionItemContainer: React.FunctionComponent<
  React.ComponentPropsWithRef<"div"> & {
    $isChecked?: boolean;
    interfacesTheme?: boolean;
  }
> = ({ $isChecked, interfacesTheme, ...props }) => (
  <div
    {...props}
    role="option"
    aria-selected={$isChecked}
    className={cn(
      "flex h-[50px] w-full cursor-pointer items-center gap-2.5 border-2 pl-2.5 transition-colors duration-150",
      {
        "rounded-lg border-zi-lightGray bg-zi-superLightGray": !interfacesTheme,
        "rounded-large border-input bg-card text-card-foreground":
          interfacesTheme,
      },
      {
        "border-zi-primary text-zi-primary": $isChecked && !interfacesTheme,
        "border-primary text-primary": $isChecked && interfacesTheme,
      },
      props.className
    )}
  />
);

const OptionItemSingleSelect = ({
  checked,
  label,
  onClick,
}: {
  checked: boolean;
  label: string;
  onClick?: () => void;
}) => {
  const interfacesTheme = !!useInterfacesTheme();
  return (
    <OptionItemContainer
      $isChecked={checked}
      onClick={onClick}
      interfacesTheme={interfacesTheme}
    >
      {checked ? <IconOptionCircleChecked /> : <IconOptionCircleUnchecked />}
      <Label interfacesTheme={interfacesTheme}>{label}</Label>
    </OptionItemContainer>
  );
};

const OptionItemMultiSelect = ({
  checked,
  label,
  onClick,
}: {
  checked: boolean;
  label: string;
  onClick?: () => void;
}) => {
  const interfacesTheme = !!useInterfacesTheme();
  return (
    <OptionItemContainer
      $isChecked={checked}
      onClick={onClick}
      interfacesTheme={interfacesTheme}
    >
      {checked ? <IconOptionSquareChecked /> : <IconOptionSquareUnchecked />}
      <Label interfacesTheme={interfacesTheme}>{label}</Label>
    </OptionItemContainer>
  );
};

type Props = {
  value?: any;
  onChange?: (value?: any) => void;
  options: { label: string; value: any }[];
  multiSelect?: boolean;
  name?: string;
  isErrored?: boolean;
};

export const InlineSelect = forwardRef<HTMLDivElement, Props>(
  ({ value, onChange, options, multiSelect }, ref) => {
    const [selectedValue, setSelectedValue] = useState<
      boolean | string | string[] | undefined
    >(value);

    const handleClick = (option: { label: string; value: any }) => {
      if (multiSelect) {
        if (Array.isArray(value)) {
          if (!value.includes(option.value)) {
            setSelectedValue([...value, option.value]);
          } else {
            setSelectedValue(value.filter((v) => v !== option.value));
          }
        } else {
          setSelectedValue([option.value]);
        }
      } else {
        if (value === option.value) {
          setSelectedValue(undefined);
        } else {
          setSelectedValue(option.value);
        }
      }
    };

    const handleChange = useMemo(() => {
      return onChange !== undefined
        ? (value: unknown) => {
            onChange(value);
          }
        : undefined;
    }, [onChange]);

    useEffect(() => {
      handleChange?.(selectedValue);
    }, [selectedValue, handleChange]);

    const isSelected = (optionValue: any) => {
      if (Array.isArray(value)) {
        return value.includes(optionValue);
      } else {
        return value === optionValue;
      }
    };

    const renderOptions = () => {
      if (multiSelect) {
        return options.map((option) => (
          <OptionItemMultiSelect
            key={option.value}
            checked={isSelected(option.value)}
            label={option.label}
            onClick={() => handleClick(option)}
          />
        ));
      } else {
        return options.map((option) => (
          <OptionItemSingleSelect
            key={option.value}
            checked={value === option.value}
            label={option.label}
            onClick={() => handleClick(option)}
          />
        ));
      }
    };

    return (
      <div className="flex flex-col gap-2.5" ref={ref} role="listbox">
        {renderOptions()}
      </div>
    );
  }
);

InlineSelect.displayName = "InlineSelect";
