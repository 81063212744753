import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe, type Stripe } from "@stripe/stripe-js";
import { Icon, Modal } from "@zapier/design-system";
import { animateTransition } from "block-system/blocks/__shared__/transition";
import { StripeCheckoutSuccess } from "block-system/blocks/StripePayment/Block/StripeCheckoutStatusModal";
import { useMemo, useState } from "react";
import { config } from "server/config";
import { cn } from "utils/cn";

/**
 * This component is only used in in the "non-redirect" context.
 * Meaning, for the payment to complete, the user does not have to be redirected anywhere.
 * As such, the errors that can occur during the payment process are handled within the `EmbeddedCheckout` component.
 *
 * The `CheckoutModalState` mimics values from https://docs.stripe.com/api/checkout/sessions/object#checkout_session_object-status
 */
type CheckoutModalState = "open" | "complete";

export function StripeEmbeddedCheckoutModal({
  connectedAccountId,
  clientSecret,
  onClosed,
  onComplete,
}: {
  connectedAccountId: string;
  clientSecret: string;
  onClosed: () => void;
  onComplete: () => void;
}) {
  const [checkoutState, setCheckoutState] =
    useState<CheckoutModalState>("open");

  const stripePromise = useStripePromise({ connectedAccountId });

  return (
    <Modal onClosed={onClosed}>
      <div
        className={cn(
          {
            "px-[40px] pb-[40px]": checkoutState === "open",
            "p-[40px]": checkoutState === "complete",
          },
          /**
           * The width values were hand-picked to make the form within the modal look relatively nice.
           */
          "relative min-h-[200px] w-[clamp(400px,492px,560px)] rounded-[12px] border border-neutral-200 bg-zi-white shadow-[0px_12px_40px_0_rgba(0,0,0,0.15)]"
        )}
      >
        <button
          className={cn(
            "absolute right-[15px] top-[15px] flex items-center text-zi-gray"
          )}
          type="button"
          onClick={() => {
            animateTransition(() => {
              onClosed();
            });
          }}
        >
          <Icon name="formX" size={30} ariaHidden={true} />
          <span className="sr-only">Close modal</span>
        </button>
        {checkoutState === "open" ? (
          <CheckingOut
            clientSecret={clientSecret}
            stripePromise={stripePromise}
            onComplete={() => {
              onComplete();
              animateTransition(() => {
                setCheckoutState("complete");
              });
            }}
          />
        ) : (
          <StripeCheckoutSuccess
            onClose={() => {
              animateTransition(() => {
                onClosed();
              });
            }}
          />
        )}
      </div>
    </Modal>
  );
}

function CheckingOut({
  stripePromise,
  onComplete,
  clientSecret,
}: {
  stripePromise: Promise<Stripe | null>;
  onComplete: () => void;
  clientSecret: string;
}) {
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{
        onComplete: () => {
          onComplete();
        },
        clientSecret,
      }}
    >
      {/* Note that we can't style the `EmbeddedCheckout`, even when providing the `className` prop.
      The `EmbeddedCheckout` renders elements within the `iframe` and we can't "reach into" the iframe with CSS. */}
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
}

function useStripePromise({
  connectedAccountId,
}: {
  connectedAccountId: string;
}) {
  const stripePromise = useMemo(() => {
    return loadStripe(config().NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY, {
      stripeAccount: connectedAccountId,
    });
  }, [connectedAccountId]);

  return stripePromise;
}
