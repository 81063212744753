import { BlockId, ContentBlock } from "block-system/types";
import {
  Divider,
  Embed,
  Field,
  Form,
  Kanban,
  Layout,
  Links,
  LinksCard,
  MarkdownText,
  Table,
  Checklist,
  ButtonBlockDefinition,
} from "block-system/blocks";
import { Media } from "block-system/blocks/Media";
import {
  useIsChecklistComponentEnabled,
  useIsEmbedComponentEnabled,
  useIsStripePaymentComponentEnabled,
} from "../lib/context/split-context";
import { EmbedBlock } from "./blocks/Embed/Block";
import { BlockSpinner } from "./components/BlockSpinner";
import dynamic from "next/dynamic";

// These components can be server-side-rendered.
import { DividerBlock } from "block-system/blocks/Divider/Block";
import { FieldBlock } from "block-system/blocks/Form/Field/Block";
import { FormBlock } from "block-system/blocks/Form/Form/Block";
import { LinksBlock } from "block-system/blocks/Links/Links/Block";
import { LinksCardBlock } from "block-system/blocks/Links/LinksCard/Block";
import { MarkdownTextBlock } from "block-system/blocks/MarkdownText/Block";
import { MediaBlock } from "block-system/blocks/Media/Block";
import { KanbanSkeleton } from "./blocks/Kanban/Block/components/Skeleton";
import { ButtonBlock } from "./blocks/Button/Block";
import { LayoutBlock } from "./blocks/Layout/Block";
import { ChecklistSkeleton } from "block-system/blocks/Checklist/Block/ChecklistSkeleton";
import { StripePayment } from "./blocks/StripePayment";
import { StripePaymentBlock } from "./blocks/StripePayment/Block";

// These components cannot be server-side-rendered.
const TableBlock = dynamic(
  async () => {
    const component = await import("block-system/blocks/Table/Block");
    return component.TableBlock;
  },
  { loading: BlockSpinner, ssr: false }
);

const KanbanBlock = dynamic(
  async () => {
    const component = await import("block-system/blocks/Kanban/Block");
    return component.KanbanBlock;
  },
  { loading: KanbanSkeleton, ssr: false }
);

const ChatbotBlock = dynamic(
  async () => {
    const component = await import("block-system/blocks/Chatbot/Block");
    return component.ChatbotBlock;
  },
  { loading: BlockSpinner, ssr: false }
);

const ChecklistBlock = dynamic(
  async () => {
    const component = await import("block-system/blocks/Checklist/Block");
    return component.ChecklistBlock;
  },
  { loading: ChecklistSkeleton, ssr: false }
);

export function Block({
  block,
  blocks,
  blockId,
}: {
  block: ContentBlock;
  blocks: ContentBlock[];
  blockId: BlockId;
}) {
  const type = block.type;

  const isEmbedEnabled = useIsEmbedComponentEnabled();
  const isChecklistEnabled = useIsChecklistComponentEnabled();
  const isStripePaymentEnabled = useIsStripePaymentComponentEnabled();

  switch (type) {
    case MarkdownText.blockType:
      return <MarkdownTextBlock block={block} blockId={blockId} />;
    case Form.blockType:
      return <FormBlock block={block} blockId={blockId} />;
    case Field.blockType:
      return <FieldBlock block={block} blocks={blocks} blockId={blockId} />;
    case Table.blockType:
      return <TableBlock block={block} blockId={blockId} />;
    case Kanban.blockType:
      return <KanbanBlock block={block} blockId={blockId} />;
    case Links.blockType:
      return <LinksBlock block={block} blockId={blockId} />;
    case LinksCard.blockType:
      return <LinksCardBlock block={block} blockId={blockId} />;
    case "chatbot-block":
      return <ChatbotBlock block={block} blockId={blockId} />;
    case Media.blockType:
      return <MediaBlock block={block} blockId={blockId} />;
    case Divider.blockType:
      return <DividerBlock block={block} blockId={blockId} />;
    case Embed.blockType: {
      if (isEmbedEnabled) {
        return <EmbedBlock block={block} blockId={blockId} />;
      }

      return null;
    }
    case ButtonBlockDefinition.blockType:
      return <ButtonBlock block={block} blockId={blockId} />;

    case Checklist.blockType: {
      if (isChecklistEnabled) {
        return <ChecklistBlock block={block} blockId={blockId} />;
      }

      return null;
    }
    case Layout.blockType:
      return <LayoutBlock block={block} blockId={blockId} />;

    case StripePayment.blockType: {
      if (isStripePaymentEnabled) {
        return <StripePaymentBlock block={block} blockId={blockId} />;
      }

      return null;
    }
    default:
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
  }
}
