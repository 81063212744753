import { truncate } from "lodash";
import { cn } from "utils/cn";

type props = {
  value: string;
  className?: string;
};

export const AIFormula: React.FunctionComponent<props> = ({
  value,
  className,
}) => (
  <div
    className={cn(
      "color-container min-h-28 rounded border bg-gray-50 px-3 py-4",
      className
    )}
  >
    {truncate(value, { length: 250 })}
  </div>
);
